import React from "react";
import { Footer, NavBar } from "../components";

const Layout1 = ({ children }) => {
  return (
    <>

      {children}
      {/* <Footer /> */}
    </>
  );
};

export default Layout1;

